
        @import "src/assets/styles/_variables.scss";
        $url: "https://static.iworld.com";
      
.note {
  position: absolute;
  z-index: 2;
  right: 0px;
  top: 0px;
  transform: translate(35%, -100%) rotate(45deg);
  transform-origin: bottom left;
}
.noteInner {
  width: 111px;
  border: 32px solid transparent;
  border-bottom-color: #90D285;
  margin-top: 0;
  height: 0;
  position: relative;
  margin-right: 10px;

  &:before, &:after {
    content: '';
    position: absolute;
    bottom: 0;
    border: 3px solid transparent;
    transform: translateY(10px);
  }

  &:before {
    border-top-color: #497243;
    border-left-color: #497243;
    left: -32px;
    bottom: -28px;
  }

  &:after {
    border-top-color: #497243;
    border-right-color: #497243;
    right: -31px;
    bottom: -28px;
  }

  span {
    position: absolute;
    left: 50%;
    font-size: 16px;
    line-height: 1em;
    font-weight: 600;
    color: #fff;
    transform: translateX(-50%);
    bottom: -26px;
  }
}


@media (max-width: 575px) {
  .noteInner {
    margin-top: -1px;
    margin-right: 9px;
  }
}

:global(.isMobile) {
  .noteInner {
    margin-top: -10px;
    margin-right: 5px;
  }
}
