
        @import "src/assets/styles/_variables.scss";
        $url: "https://static.iworld.com";
      
.banner {
  display: flex;
  position: relative;
  padding: 4px;
  margin: 10px 9px 9px;
  width: 270px;
}

.container {
  display: flex;
  background: url('/img/banners/real-estate/line-hor.png') no-repeat center center,
  url('/img/banners/real-estate/menu-img.png') no-repeat top center,
  url('/img/banners/real-estate/bg.jpg') no-repeat bottom center;
  background-size: 103% auto;
  padding: 3px;
}

.bannerHor {
  display: flex;
  position: relative;
  padding: 7px;
  margin: 10px 0 0;
  background: url('/img/banners/real-estate/hor-bg.webp') no-repeat top left / 65% 100%,
  url('/img/banners/real-estate/hor-bg2.webp') no-repeat top right / auto 100%;
  height: 280px;
  width: 100vw;
  @media #{$sm}{
    background: url('/img/banners/real-estate/hor-bg.webp') no-repeat top left / auto 100%;
  }
  :global(.no-webp) & {
    background: url('/img/banners/real-estate/hor-bg.png') no-repeat top left / 65% 100%,
    url('/img/banners/real-estate/hor-bg2.jpg') no-repeat top right / auto 100%;
    @media #{$sm}{
      background: url('/img/banners/real-estate/hor-bg.png') no-repeat top left / auto 100%;
    }
  }
}


.wrapper{
  width: 100%;
  max-width: 1440px;
  margin: auto;
  padding: 0 20px;
}

.wrap{
  position: relative;
  width: 100%;
  height: 38%;
  margin: auto 0 0;
  padding: 0 10px;
}

.wrapHor{
  position: relative;
  width: 50%;
  height: 100%;
  margin: 0 auto 0 0;
  @media #{$sm}{
    width: fit-content;
    margin: auto;
  }
}

.title{
  font-weight: 700;
  font-size: 22px;
  line-height: 130%;
  color: #fff;
  white-space: break-spaces;
  padding-bottom: 16px;
}
.titleHor{
  font-size: 32px;
}

ul.text{
  color: #fff;
  font-weight: 500;
  font-size: 13px;
  line-height: 140%;
  list-style-position: inside;
  list-style-type: disc;
  margin-bottom: 16px;
  & > li{
    padding-bottom: 6px;
  }
}
ul.textHor {
  font-size: 16px;
}

.getConsultation {
  display: flex;
  align-items: center;
  white-space: nowrap;
  background: #FFFFFF;
  font-size: 13px;
  line-height: 18px;
  font-weight: 600;
  color: #000000;
  height: 44px;
  margin: auto 0 9px;
  width: 100%;
  justify-content: center;
  position: relative;
  svg{
    right: 8px;
    position: absolute;
  }
  &:hover {
    color: #000000;
  }
}

.getConsultationHor {
  max-width: 320px;
}
